<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>{{ saveName }}</ion-title>
        <ion-buttons v-if="currentSaveId" slot="end">
          <ion-button @click="reloadRecents">
            <ion-icon :icon="icons.refreshOutline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true" padding>
      <!-- เลือก แฟ้ม ที่จะใช้งานแล้ว -->
      <template v-if="currentSaveId">
        <!-- มีบัญชี และมี หมวดหมู่ -->
        <template v-if="assetsCount>0 && categoriesCount>0">
          <!-- refresher -->
          <ion-refresher
            slot="fixed"
            @ionRefresh="reloadRecents($event)"
            pull-min="50"
            snapback-duration="1000ms"
          >
            <ion-refresher-content
              :pulling-icon="icons.chevronDownCircleOutline"
              pulling-text="Pull to refresh"
              refreshing-spinner="circles"
              refreshing-text="Getting..."
            >
            </ion-refresher-content>
          </ion-refresher>
          <!-- three create buttons -->
          <ion-button
            expand="full"
            @click="openInputtingForm('expense')"
            color="danger"
          >
            Expense
          </ion-button>
          <ion-button expand="full" @click="openInputtingForm('transfer')">
            Transfer
          </ion-button>
          <ion-button
            expand="full"
            @click="openInputtingForm('income')"
            color="success"
          >
            Income
          </ion-button>
          <!-- Recent Transactions -->
          <transaction-list
            :transactions="recentTransactions"
            :show-datetime="false"
            :loading-transactions="loading"
            @change="reloadRecents"
          ></transaction-list>
        </template>
        <!-- ยังไม่มี บัญชี/หมวดหมู่ -->
        <template v-else>
          <section class="container">
            <h2>ยังไม่มีบัญชี หรือ หมวดหมู่</h2>
            <p>สามารถสร้างได้ที่เมนูตั้งค่า</p>
            <ion-button expand="full" :router-link="{ name: 'settings' }">ไปยังเมนูตั้งค่า</ion-button>
          </section>
        </template>
      </template>
      <!-- No SaveId : Let's user choose one -->
      <template v-else>
        <!-- มี save แล้ว กดเลือกใช้ได้เลย -->
        <template v-if="savesCount">
          <ion-list>
            <ion-list-header>เลือกแฟ้มที่จะใช้งาน</ion-list-header>
            <ion-item
              v-for="save in listSaves"
              :key="save.save_id"
              button
              detail="false"
              @click="useSave({ save_id: save.save_id })"
              lines="full"
            >
              <ion-icon slot="start" :ios="icons.folderOutline" :md="icons.folderSharp"></ion-icon>
              <ion-label>{{save.save_name}}</ion-label>
            </ion-item>
          </ion-list>
        </template>
        <!-- ยังไม่มี ให้กดไปสร้าง ที่หน้า Settings -->
        <template v-else>
          <section class="container">
            <h2>ยังไม่มีแฟ้มข้อมูล</h2>
            <p>สามารถสร้างแฟ้มข้อมูลได้ที่เมนูตั้งค่า</p>
            <ion-button expand="full" :router-link="{ name: 'settings' }">ไปยังเมนูตั้งค่า</ion-button>
          </section>
        </template>
      </template>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  modalController,
  // Vue Components
  IonButton,
  IonButtons,
  IonIcon,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenuButton,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
} from "@ionic/vue";
import { mapActions, mapGetters } from "vuex";
import ModalTransactionEditor from "../../components/ModalTransactionEditor.vue";
import {
  chevronDownCircleOutline,
  fileTrayFullOutline,
  fileTrayFullSharp,
  pencilOutline,
  pencilSharp,
  refreshOutline,
  trashOutline,
  trashSharp,
} from "ionicons/icons";
import { numberFormatComma } from "../../../helper.js";
import TransactionList from "../../components/TransactionList.vue";

export default {
  name: "Main",
  components: {
    IonButton,
    IonButtons,
    IonIcon,
    IonContent,
    IonHeader,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenuButton,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonTitle,
    IonToolbar,
    TransactionList,
  },
  data() {
    return {
      loading: false,
      isOpenInputtingForm: false,
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: "auth/isLoggedIn",
      recentTransactions: "save/recentTransactions",
      hashedAccounts: "account/hashedAccounts",
      hashedCategories: "account/hashedCategories",
      currentSaveId: "save/currentSaveId",
      listSaves: "save/saves",
      savesCount: "save/savesCount",
      assetsCount: "account/currentSaveAssetsCount",
      categoriesCount: "account/currentSaveCategoriesCount",
    }),
    saveName() {
      return this.$store.getters["save/currentSaveName"] ? this.$store.getters["save/currentSaveName"] : "";
    },
    icons() {
      return {
        chevronDownCircleOutline,
        refreshOutline,
        deleteOutline: trashOutline,
        deleteSharp: trashSharp,
        editOutline: pencilOutline,
        editSharp: pencilSharp,
        folderOutline: fileTrayFullOutline,
        folderSharp: fileTrayFullSharp,
      };
    },
  },
  methods: {
    ...mapActions({
      loadRecentTransactions: "save/loadRecentTransactions",
      deleteTransaction: "save/deleteTransaction",
      useSave: "save/useSave",
    }),
    number_format(number) {
      return numberFormatComma(number);
    },
    async openInputtingForm(mode) {
      if (this.isOpenInputtingForm)
        return;

      // จะเปิดแล้ว
      this.isOpenInputtingForm = true;

      const modal = await modalController.create({
        component: ModalTransactionEditor,
        componentProps: {
          initMode: mode,
        },
        swipeToClose: false,
      });
      modal.onWillDismiss().then((receiving) => {
        if (receiving.data) { // มีการเปลี่ยนแปลงเกิดขึ้น
          this.loadRecentTransactions();
        }
        // ปิดแล้ว
        this.isOpenInputtingForm = false;
      });
      return modal.present();
    },
    async reloadRecents(event) {
      try {
        this.loading = true;
        await this.loadRecentTransactions();
      } finally {
        this.loading = false;
        if (event && event.target && typeof event.target.complete === "function") {
          event.target.complete();
        }
      }
    },
  },
  mounted() {
    if (
      this.isLoggedIn &&
      this.currentSaveId &&
      this.recentTransactions.length === 0
    ) {
      this.reloadRecents();
    }
  },
  watch: {
    currentSaveId(newValue, oldValue) {
      if (!oldValue && newValue) {
        this.loadRecentTransactions();
      }
    },
  },
};
</script>

<style scoped>
section.container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 1em;
}
</style>
